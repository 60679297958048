import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { AppContext } from './context/AppContext';
import DigitalClock from './DigitalClock';
import { useContext, useEffect, useRef, componentDidMount } from 'react';
import { useWakeLock } from 'react-screen-wake-lock';

function Main(props) {
    const { FLAGS, setFlags, timer, setTimer, running, setRunning, events, setEvents,
        period, setPeriod, counters, setCounters, teams, setTeams, goalies,setGoalies } = useContext(AppContext);
    const GlobalFlags = FLAGS;

    const navigate = useNavigate();

    useEffect(() => {
        // every time I go to the main page, close the popup
        props.hideHamburger();
    }, [])

    function saveDataLocal() {
        localStorage.setItem("hockeyData.flags", JSON.stringify(FLAGS));
        localStorage.setItem("hockeyData.counters", JSON.stringify(counters));
        localStorage.setItem("hockeyData.teams", JSON.stringify(teams));
        localStorage.setItem("hockeyData.goalies", JSON.stringify(goalies));
        localStorage.setItem("hockeyData.events", JSON.stringify(events));
        if (GlobalFlags.debug) {
            console.log("savingData", events);
            console.log("savingData", FLAGS);
            console.log("savingData", counters);
            console.log("savingData", teams);
            console.log("savingData", goalies);
        }
    }
    useEffect(() => { saveDataLocal(); }, [events]);

    const listRef = useRef(null);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        };
    }, [events]);

    // Function to increment a specific counter
    const incrementCounter = (property) => {
        setCounters({
            ...counters,
            [property]: counters[property] + 1,
        });
    };

    const decrementCounter = (property) => {
        setCounters({
            ...counters,
            [property]: counters[property] - 1,
        });
    };

    function resetData() {
        const confirmBox = window.confirm(
            "Do you really want to Reset data"
        )
        if (confirmBox === true) {
            stopTimer();
            setEvents([]);
            setGoalies( { home: 'Default', visitor: 'Default' });
            setCounters({
                HomeGoal: 0,
                VisitorShot: 0,
                HomeShot: 0,
                VisitorGoal: 0,
                HomeMiss: 0,
                VisitorMiss: 0
            });// end setCOunters
      setTeams ( { homeShortName: '-', visitorShortName: '-' });
        }
    }

    function startTimer() {
        setRunning(true);
    }

    function stopTimer() {
        setRunning(false);
    }

    function recordEvent(eventType) {
        setEvents([...events, { period: period, time: fullShort(timer), eventType: eventType }]);
        incrementCounter(eventType);
    }

    return (<div id='mainApp'>
        <h1>Score Tracker</h1>
               {/* <Settings document={document} flags={FLAGS} setFlags={setFlags} /> */}
        {/* <ClockTime className='clockTable' running={running} timer={timer} setTimer={setTimer} setTime={setTimer} startTimer={startTimer} */}
        {/* stopTimer={stopTimer} setPeriod={setPeriod} period={period} document={document} /> */}
        <DigitalClock showMicroAdjust={true} />
        <div id='counterDiv'>
            <table><tbody><tr onClick={() => navigate('/setteamname')}>
                <td>Home<br />{teams.homeShortName}</td><td></td>
                <td>Visitor<br />{teams.visitorShortName}</td></tr>
                <tr><td onClick={() => recordEvent("HomeGoal")} className='digitalNumber countertd'>{counters.HomeGoal}</td><td>Goals</td>
                    <td onClick={() => recordEvent("VisitorGoal")} className='digitalNumber countertd'>{counters.VisitorGoal}</td></tr>
                <tr><td onClick={() => recordEvent("HomeShot")} className='digitalNumber countertd'>{counters.HomeShot}</td><td>Shots</td>
                    <td onClick={() => recordEvent("VisitorShot")} className='digitalNumber countertd'>{counters.VisitorShot}</td></tr>
                {FLAGS.trackMisses ? (<tr><td onClick={() => recordEvent("HomeMiss")} className='digitalNumber countertd'>{counters.HomeMiss}</td><td>Miss</td>
                    <td onClick={() => recordEvent("VisitorMiss")} className='digitalNumber countertd'>{counters.VisitorMiss}</td></tr>
                ) : <tr></tr>}
            </tbody></table>
        </div> {/*end counterDiv */}

        <div>
            <button className='start' disabled={running} onClick={startTimer}>Start</button>
            <button className='stop' disabled={!running} onClick={stopTimer}>Stop</button>
            {/* {FLAGS.editEvent && <EditEventWindow className='editEventWindow' events={events} setEvents={setEvents} decrementCounter={decrementCounter} document={document} />} */}
        </div>
        <div className='extraSettings'>
        <button id='setGoalieButton' className='setGoalie' onClick={()=>navigate('/setgoalie')} >Set Goalies</button>
        </div>
         {/* return(   <button onClick={() => navigate('/')}>Return</button>) */}
        <button id='resetButton' className='reset' onClick={resetData}>Reset</button>
        <button id='saveButton' onClick={() => handleDownload(events, counters, teams)}>Download</button>
        {FLAGS.uploadData && <button id='uploadButton' onClick={() => handleUpload(events, counters)}>Save Online</button>}
        {/* <Link to="/editevents" > */}
        <div className="scrollable-div eventWindow" id="scrollable-div" ref={listRef} onClick={() => navigate('/editevents')}>
            <ul>
                {events.map((event, index) => (
                    <li className='event' key={index}>
                        {event.period},{event.time},{event.eventType}
                    </li>
                ))}
            </ul>
        </div>
        {/* </Link>  */}
    </div>
    )

}


function editEventWindow() {
    console.log("edit Event Window")
}
function handleUpload(events, counters) {
    const confirmBox = window.confirm(
        "Upload is not Implemented yet"
    )
    console.log('Uploading not implemented ' + (confirmBox ? "ok" : "cancel"))
}

function fullShort(timer) {
    if (timer < 60) {
        return formatSeconds(timer, 2);
    } else {
        return formatSeconds(timer, 0);
    }
}

function formatSeconds(seconds, decimalPlaces = 0) {

    // show different times depending on "stuff"
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format minutes and seconds with leading zeros
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    // If decimal places are required, calculate the fractional part
    if (decimalPlaces > 0) {
        // const fractionalSeconds = (remainingSeconds - Math.floor(remainingSeconds)).toFixed(decimalPlaces);
        // return `${formattedMinutes}:${formattedSeconds}${fractionalSeconds.substring(1)}`;
        const fractionalSeconds = Math.floor((seconds - Math.floor(seconds)) * (10 ** decimalPlaces));
        const formattedfractionalSeconds = String(fractionalSeconds).padStart(decimalPlaces, '0');


        // \?).toFixed(decimalPlaces);
        return `${formattedMinutes}:${formattedSeconds}.${formattedfractionalSeconds}`;
    } else {
        return `${formattedMinutes}:${formattedSeconds}`;
    }
}

function savePercentage(goals, shots) {
    return (Math.floor((1 - goals / shots) * 1000) / 10 + '%')
}

function handleDownload(events, counters, teams) {
    const data = events
    const csvContent = "data:text/csv;charset=utf-8,"
        + "Game Summary\n"
        + "Home Short Name: " + teams.homeShortName + "\n"
        + " Age & Division: " + teams.homeAgeDivision + "\n"
        + "Visitor Short Name: " + teams.visitorShortName + "\n"
        + " Age & Division: " + teams.visitorAgeDivision + "\n"
        + "Home Save: " + savePercentage(counters.VisitorGoal, counters.VisitorShot) + '\n'
        + "Visitor Save:  " + savePercentage(counters.HomeGoal, counters.HomeShot) + '\n'
        + "Home Goal: " + counters.HomeGoal + '\n'
        + "Visitor Goal: " + counters.VisitorGoal + '\n'
        + "Home Shots: " + counters.HomeShot + '\n'
        + "Visitor Shots: " + counters.VisitorShot + '\n'
        + "Home Miss: " + counters.HomeMiss + '\n'
        + "Visitor Miss: " + counters.VisitorMiss + '\n'
        + data.map(e => Object.values(e).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const isoDate = `${year}-${month}-${day}`;
    console.log(isoDate);

    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `HockeyShotData-${isoDate}.txt`);
    document.body.appendChild(link); // Required for Firefox
    link.click();
    link.remove();

}


export default Main;

